import React, { useState, useEffect, useRef } from 'react'
import Image from 'next/image'

// Import your images
import angleWhite from '../assets/images/angleDownWhite.svg'
import cc1Image from '../assets/images/contactUs/cc1.jpg'
import cc2Image from '../assets/images/contactUs/cc2.jpg'
import cc3Image from '../assets/images/contactUs/cc3.jpg'
import cc4Image from '../assets/images/contactUs/cc4.jpg'
import chatIconImage from '../assets/images/chatIcon.svg'
import phoneIconImage from '../assets/images/phoneIcon.svg'
import whatsappIcon from '../assets/images/whatsapp.png'
import Link from 'next/link'

const ContactUsDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const closeDropdown = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    window.addEventListener('click', closeDropdown)

    return () => {
      window.removeEventListener('click', closeDropdown)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const dropdownContentStyles = {
    opacity: isOpen ? 1 : 0, // Show/hide the dropdown
    pointerEvents: isOpen ? 'auto' : 'none', // Enable/disable pointer events
    position: 'absolute',
    top: '56px',
    right: 0,
    zIndex: 10,
    width: '330px',
    transition: 'all 600ms ease-in-out',
    transformOrigin: 'top right',
    background: 'white',
    outline: 'none',
    padding: '25px 10px 15px 10px',
    borderRadius: '0 0 10px 10px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)'
  }

  return (
    <li className='relative' ref={dropdownRef}>
      <button
        // href='/contact-us'
        id='dropdown-button3'
        className='hover:bg-[#3DABC2] group bg-[#F57415] pe-6  text-sm 1200-1300:text-[13px] 992-1199:pe-5 992-1199:text-[11px] text-white rounded-full flex items-center relative'
      >
        <Link href='/contact-us' className='py-3.5 ps-6 pe-[12px] 992-1199:py-3 font-semibold'>Contact us</Link>
        <span className='block w-[1px] h-[20px] bg-[#D85C02] group-hover:bg-[#FFF] me-[12px]'></span>
        <Image src={angleWhite} onClick={toggleDropdown} alt='Dropdown Arrow' className={`transition duration-[.6s] ease-in-out ${isOpen && 'rotate-180'}`} />
      </button>
      <div id='dropdown-menu3' style={dropdownContentStyles}>
        <span className='font-poppins-semibold text-lg  leading-[22px] tracking-[-0.18px] text-center text-black block pb-2'>
          Contact us
        </span>
        <div className='flex -space-x-2 justify-center py-4'>
          <Image
            src={cc1Image}
            alt='Image Alt Text'
            width={120}
            height={120}
            className='inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-contact'
          />
          <Image
            src={cc2Image}
            alt='Image Alt Text'
            width={120}
            height={120}
            className='inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-contact'
          />
          <Image
            src={cc3Image}
            alt='Image Alt Text'
            width={120}
            height={120}
            className='inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-contact'
          />
          <Image
            src={cc4Image}
            alt='Image Alt Text'
            width={120}
            height={120}
            className='inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-contact'
          />
        </div>
        <p className='font-poppins-regular text-[13px]  leading-[18px] tracking-[0.26px] text-center text-[#4A748B] pt-2'>
          Our travel consultants are available around the clock to help you plan your next trip.
        </p>
        <div className='flex items-center justify-center gap-2 border-b border-solid border-[#D6E8EF] py-3 pb-4'>
          <Image src={chatIconImage} alt='Chat Icon' />
          <span className='text-[13px] text-black font-poppins-semibold  leading-[24px] tracking-[0.13px]'>
            Chat with us live
          </span>
        </div>
        <div className='flex items-center justify-center gap-2 py-4 pb-1'>
          <Image src={phoneIconImage} alt='Phone Icon' />
          <a
            href='tel:310-915-6677'
            className='text-[14px] text-[#3DABC2] font-poppins-semibold  leading-[24px] tracking-[4.2px]'
          >
            310-915-6677
          </a>
        </div>
        <div className='flex items-center justify-center gap-2 py-4 pb-1'>
          <Image src={whatsappIcon} alt='Phone Icon' />
          <a
            href='https://wa.me/3109152647'
            target='_blank'
            className='text-[14px] text-[#3DABC2] font-poppins-semibold  leading-[24px] tracking-[4.2px]'
          >
            310-915-2647
          </a>
        </div>
        <a
          href='mailto:info@bluewaterdivetravel.com'
          className='text-[14px] text-[#3DABC2] font-poppins-semibold  leading-[24px] tracking-[1.12px] py-3 pb-1 text-center block'
        >
          info@bluewaterdivetravel.com
        </a>
      </div>
    </li>
  )
}

export default ContactUsDropdown
