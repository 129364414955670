import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import searchWhite from "/assets/images/searchWhite.svg";
import crossWhite from "/assets/images/crossWhite.svg";
import globeImage from "/assets/images/Globe.png";
import sailboatImage from "/assets/images/Sailboat.png";
import sunshadeImage from "/assets/images/Sunshade.png";
import tripsImage from "/assets/images/Trips.png";
import {headerSearch, mainSearch} from "../services/dropdown";

const SearchBar = ({ isShow, onClick }) => {
  const [val, setVal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [resortItems, setResortItems] = useState([]);
  const [liveaboardItems, setLiveaboardItems] = useState([]);
  const [groupTrips, setGroupTrips] = useState([]);
  const [articles, setArticles] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const searchBarRef = useRef(null);

  const handleValChange = async (e) => {
    const value = e.target.value.toLowerCase();
    setVal(value);
    if (value.length > 2){
      let res = await headerSearch(value);
      if (res?.data?.resorts?.length > 0){
        setResortItems(res?.data?.resorts)
      }
      if (res?.data?.liveaboards?.length > 0){
        setLiveaboardItems(res?.data?.liveaboards)
      }if (res?.data?.group_trips?.length > 0){
        setGroupTrips(res?.data?.group_trips)
      }if (res?.data?.articles?.length > 0){
        setArticles(res?.data?.articles)
      }
      if (res?.data?.destinations?.length > 0){
        setDestinations(res?.data?.destinations)
      }

    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
        onClick();
      }
      };
      
      !isShow && setVal("");

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick]);

  const allItems = [
    {
      name: "Pindito Liveaboard",
      location: "Indonesia",
      image: sailboatImage,
    },
    {
      name: "Beqa Lagoon Resort",
      location: "Fiji",
      image: sunshadeImage,
    },
    {
      name: "Alor Destination",
      location: "Southeast Asia",
      image: globeImage,
    },
    {
      name: "Cuba Underwater Photo Workshop",
      location: "Cuba",
      image: tripsImage,
    },
  ];

  return (
    <div
      ref={searchBarRef}
      className={`search-bar fixed w-full h-[30vh] 768-991:h-[35vh] ${
        isShow ? "top-[0]" : "-top-[100%]"
      } left-0 bg-[rgba(61,172,194,.5)] backdrop-blur-[5px] z-20 flex items-center justify-center transition-all duration-6000 ease-in-out`}
    >
      <div className="flex w-[50%] 768-991:w-[80%] bg-white border border-solid border-[#3DABC2] relative">
        <input
          type="text"
          placeholder="Type Here To Search..."
          value={val}
          onChange={handleValChange}
          className="w-full outline-none bg-white p-5 text-black font-poppins-semiBold text-[18px] "
        />
        <button
          className="bg-[#3DABC2] border border-solid border-white w-[10%] 768-991:w-[15%] flex items-center justify-center px-2"
          onClick={() => console.log("Search clicked")}
        >
          <Image
            src={searchWhite}
            alt="Search Icon"
            width={40}
            height={20}
            priority
          />
        </button>
        {val && ( // Only render if there's a value in the search input
          <div id="style-3"
            className={`absolute h-[500px] overflow-y-auto border border-solid border-[#3dabc2] min-w-[100%] top-[69px] left-0 z-[1] px-4 py-4 w-auto transition-all duration-600 ease-in-out bg-[#FFF] rounded-bl-[10px] rounded-br-[10px]`}
          >
            {resortItems.length > 0 && (
              resortItems.map((item, index) => (
                <div
                  key={index}
                  className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                >
                  <Link href={"/resort-destination/"+ (item?.parent_slug ? item?.parent_slug : item?.slug) }>
                    <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                      <Image
                        src={sunshadeImage}
                        className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                        alt="image"
                        width={24}
                        height={24}
                      />
                      <div className="flex flex-col">
                        <h6>{item.title} Resort</h6>
                        <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                          {item.parent_title}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
            {liveaboardItems.length > 0 && (
              liveaboardItems.map((item, index) => (
                <div
                  key={index}
                  className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                >
                  <Link href={"/liveaboard/"+item?.parent_slug }>
                    <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                      <Image
                        src={sunshadeImage}
                        className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                        alt="image"
                        width={24}
                        height={24}
                      />
                      <div className="flex flex-col">
                        <h6>{item.title} Liveaboard</h6>
                        <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                          {item.parent_title}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
            {groupTrips.length > 0 && (
              groupTrips.map((item, index) => (
                <div
                  key={index}
                  className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                >
                  <Link href={"/group-trip/"+item?.slug}>
                    <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                      <Image
                        src={sunshadeImage}
                        className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                        alt="image"
                        width={24}
                        height={24}
                      />
                      <div className="flex flex-col">
                        <h6>{item.title} Group Trip</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
            {articles.length > 0 && (
                articles.map((item, index) => (
                <div
                  key={index}
                  className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                >
                  <Link href={"/article/"+item?.slug}>
                    <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                      <Image
                        src={sunshadeImage}
                        className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                        alt="image"
                        width={24}
                        height={24}
                      />
                      <div className="flex flex-col">
                        <h6>{item.title} Article</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
            {destinations.length > 0 && (
                destinations.map((item, index) => (
                <div
                  key={index}
                  className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                >
                  <Link href={(item?.parent ? '/sub-destination/'+item?.parent?.slug+'/'+item?.slug : '/destination/'+item?.slug )}>
                    <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                      <Image
                        src={sunshadeImage}
                        className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                        alt="image"
                        width={24}
                        height={24}
                      />
                      <div className="flex flex-col">
                        <h6>{item.title} {(item?.parent ? 'Sub Destination' : 'Destination' )}</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
            {(resortItems?.length === 0 && liveaboardItems?.length === 0 && articles?.length === 0 && groupTrips?.length === 0 && destinations?.length === 0) && (
              <div className="text-[#4A748B] text-center font-poppins-semiBold  text-[15px]">
              No results found.
              </div>
              )}
          </div>
        )}
      </div>

      <Image
        src={crossWhite}
        alt="Close Icon"
        className="close-search absolute top-[30px] right-[30px] cursor-pointer"
        onClick={onClick}
        width={52}
        height={52}
      />
    </div>
  );
};

export default SearchBar;
