import React, {useState, useEffect} from 'react';
import {getDropDown, getRedirect} from "../services/dropdown";
import {selectMenuData, selectSiteRedirects, setMenuData,setSiteRedirects} from "../redux/reducer/userSlice";
import { useDispatch, useSelector } from 'react-redux'
import {useRouter} from "next/router";
const useHeader = () => {
    const router = useRouter();
    const [dropdownDestinationValues, setDropdownValues] = useState([]);
    const [liveaboardValues, setLiveaboardValues] = useState([]);
    const [resortValues, setResortValues] = useState([]);
    const [guidedGroupTrips, setGuided_group_trips] = useState([]);
    const dispatch = useDispatch()
    const menuData = useSelector(selectMenuData)
    const siteRedirect = useSelector(selectSiteRedirects)
    useEffect(() => {
        if (!menuData){
            getData()
        }else {
            setDropdownValues(menuData?.header_menus?.destiantion_menus)
            setLiveaboardValues(menuData?.header_menus?.liveaboard_menus)
            setResortValues(menuData?.header_menus?.resort_menus)
            setGuided_group_trips(menuData?.header_menus?.guided_group_trips)
        }
        if (!siteRedirect){
            getRedirectData();
        }

    }, []);

    const getData = async () => {
        const res =   await getDropDown();
        if (res?.status === 'success'){
            dispatch(setMenuData(res?.data))
            setDropdownValues(res?.data?.header_menus?.destiantion_menus)
            setLiveaboardValues(res?.data?.header_menus?.liveaboard_menus)
            setResortValues(res?.data?.header_menus?.resort_menus)
            setGuided_group_trips(res?.data?.header_menus?.guided_group_trips)
        }
    };
    const getRedirectData = async () => {
        const res =   await getRedirect();
        if (res?.status === 'success'){
            dispatch(setSiteRedirects(res?.data))
            localStorage.setItem('site_redirect',JSON.stringify(res?.data))
        }
    };

    return [
        dropdownDestinationValues,
        liveaboardValues,
        resortValues,
        guidedGroupTrips
    ]

};

export {useHeader};