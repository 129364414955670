import {Base_url} from "../utils/constant";

const login = async (formData) => {
    let res = await fetch(
        Base_url+`/api/login`,
        {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body:JSON.stringify(formData)
        }
    );
    return await res?.json();
};
const resetPassword = async (formData) => {
    let res = await fetch(
        Base_url+`/api/reset-password`,
        {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body:JSON.stringify(formData)
        }
    );
    return await res?.json();
};
const register = async (formDataRegister) => {
    let res = await fetch(
        Base_url+`/api/register`,
        {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body:JSON.stringify(formDataRegister)
        }
    );
    return await res?.json();
};
const forgotPassword = async (formDataRegister) => {
    let res = await fetch(
        Base_url+`/api/forgot-password`,
        {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body:JSON.stringify(formDataRegister)
        }
    );
    return await res?.json();
};
const savelatLng = async (formDataRegister) => {
    let res = await fetch(
        Base_url+`/api/profile/add-location`,
        {
            method: "POST",
            headers: new Headers({
                "content-type": "application/json" ,
                "Accept":'application/json',
                "Authorization": "Bearer "+localStorage.getItem('access_token')
            }),
            body:JSON.stringify(formDataRegister)
        }
    );
    return await res?.json();
};
const getUser = async () => {
    let res = await fetch(
        Base_url+`/api/user`,
        {
            method: "GET",
            headers: new Headers({
                "content-type": "application/json" ,
                "Accept":'application/json',
                "Authorization": "Bearer "+localStorage.getItem('access_token')
            }),

        }
    );
    return await res?.json();
};
const getUserProfile = async () => {
    let res = await fetch(
        Base_url+`/api/user-profile`,
        {
            method: "GET",
            headers: new Headers({
                "content-type": "application/json" ,
                "Accept":'application/json',
                "Authorization": "Bearer "+localStorage.getItem('access_token')
            }),

        }
    );
    return await res?.json();
};
const updateProfile = async (formDataRegister) => {
    let res = await fetch(
        Base_url+`/api/user-profile`,
        {
            method: "POST",
            headers: new Headers({
                "content-type": "application/json" ,
                "Accept":'application/json',
                "Authorization": "Bearer "+localStorage.getItem('access_token')
            }),
            body:JSON.stringify(formDataRegister)
        }
    );
    return await res?.json();
};
const logout = async () => {
    let res = await fetch(
        Base_url+`/api/logout`,
        {
            method: "POST",
            headers: new Headers({
                "content-type": "application/json" ,
                "Accept":'application/json',
                "Authorization": "Bearer "+localStorage.getItem('access_token')
            }),

        }
    );
    return await res?.json();
};
export {login,getUser,logout,register,getUserProfile,updateProfile,savelatLng,forgotPassword,resetPassword};