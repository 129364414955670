"use client";
import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import angleDown from "../assets/images/angleDown.svg";
import { useRouter } from "next/router";
import mega1 from "../assets/images/mega1.jpg";
import mega2 from "../assets/images/mega2.jpg";
import mega3 from "../assets/images/mega3.jpg";
import mega4 from "../assets/images/mega4.jpg";
import mega5 from "../assets/images/mega5.jpg";
import mega6 from "../assets/images/mega6.jpg";

const DestinationsDropdown = ({
  onClick,
  openState,
  dropdownRef,
  setAllFalse,
  destinationMenus,
  closeDestination
}) => {
  const router = useRouter();
  // const [isOpen, setIsOpen] = useState(openState)
  // const dropdownRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false);
    });
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(()=>{
          openState && closeDestination(0);
        },1000)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleLinkClick = () => {
    onClick();
  };

  return (
    <div className={`nav-link`}>
      <li ref={dropdownRef}
        className={`flex items-center gap-2 992-1199:gap-1 group btn-hover ${
          router?.pathname === "/destination/[...index]" ||
          router?.pathname === "/destination/[...index]"
            ? "btn-hover-desti"
            : ""
        }`}
      >
        <Link
          href="javascript:void(0)"
          onClick={handleLinkClick}
          className={`flex items-center gap-2 992-1199:gap-1 group btn-hover ${
            router?.pathname === "/destination/[...index]" ||
            router?.pathname === "/destination/[...index]"
              ? "text-[#F57415] btn-orange"
              : "text-black"
          }  ${
            openState && "!text-[#F57415] btn-orange"
          } hover:text-[#F57415] group-hover:text-[#F57415]`}
          id="dropdown-button"
        >
          Destinations
          <Image
            src={angleDown}
            alt="Dropdown Arrow"
            width={10}
            height={6}
            className={`transform ${
              openState ? "rotate-180" : ""
            } cursor-pointer`}
          />
        </Link>
      </li>
      {openState && (
        <>
          <div className="mega-overlay" onClick={handleLinkClick}></div>
          <div
            className={`absolute ${
              scrolled ? "top-[75px] 1260-1365:top-[74px]" : "top-[95px]"
            } h-[calc(80vh-100px)] 992-1199:px-10 left-0 -z-[1] w-full transition-all duration-600 ease-in-out origin-top-right bg-[#F8FCFF] shadow-lg focus:outline-none px-40 1366-1439:px-20 pb-10`}
          >
            <div className="flex 768-991:flex-wrap pt-10 h-full">
              <div className="768-991:w-full w-3/12 pb-5 overflow-y-auto scrollbar-hidden">
                <ul ref={dropdownRef} className="tab-list w-[90%] font-poppins-semiBold  text-[18px] 992-1023:w-full 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] tracking-[-0.18px] border-r border-solid border-[#E4F4FB]">
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 0
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(0)}
                  >
                    Southeast Asia
                  </li>
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 1
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(1)}
                  >
                    Asia Pacific
                  </li>
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 2
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(2)}
                  >
                    Caribbean
                  </li>
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 3
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(3)}
                  >
                    The Americas
                  </li>
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 4
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(4)}
                  >
                    Indian Ocean, Africa & Middle East
                  </li>
                  <li
                    className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 rounded-tl-[8px] rounded-bl-[8px] cursor-pointer transition-all duration-600 ease-in-out ${
                      activeTab === 5
                        ? "active text-[#3DABC2] bg-[#e4f4fb]"
                        : "text-black"
                    }`}
                    onClick={() => handleTabClick(5)}
                  >
                    Europe & the Polar Regions
                  </li>
                </ul>
              </div>

              <>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 0 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/article/best-diving-in-southeast-asia"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Southeast Asia Highlights
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/indonesia"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Indonesia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-in-indonesia"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Indonesia Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/Alor-Diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Alor
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/ambon-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Ambon
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/bali-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Bali
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/banda-sea-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Banda Sea
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/cenderawasih-bay-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cenderawasih Bay
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/derawan-islands-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Derawan Islands
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/komodo-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Komodo
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/lembeh-strait-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Lembeh Strait
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/lombok-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Lombok
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/manado-bunaken-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Manado And Bunaken
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/raja-ampat-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Raja Ampat
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indonesia/wakatobi-national-park-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Wakatobi National Park
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%]  w-[80%] mx-auto">
                            <Link href={"/destination/philippines"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Philippines
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-in-philippines"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Philippines Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/anilao-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Anilao
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/cebu-bohol-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cebu And Bohol
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/coron-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Coron
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/dumaguete-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Dumaguete
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/leyte-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Leyte
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/malapascua-island-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Malapascua Island
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/puerto-galera-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Puerto Galera
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/romblon-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Romblon
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/philippines/tubbataha-reef-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Tubbataha
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%]  w-[80%] mx-auto">
                            <Link href={"/destination/best-diving-in-thailand"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Thailand
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-thailand"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Thailand Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-thailand/phuket-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Phuket
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-thailand/similan-islands-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Similan Islands
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/borneo-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Borneo
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%]  w-[80%] mx-auto">
                            <Link href={"/destination/malaysia-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Malaysia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                    href={"/destination/malaysia-diving/sipadan-diving"}
                                    className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Sipadan
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/myanmar-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Myanmar
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length > 0 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[0]?.image)? destinationMenus[0]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[0]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[0]?.short_description}}>
                              </p>
                              <Link
                                  href={(destinationMenus[0]?.link_page) ? destinationMenus[0]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}

                    </div>
                  </div>
                </div>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/fiji-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Fiji
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/french-polynesia-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                French Polynesia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/french-polynesia-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  French Polynesia Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/french-polynesia-diving/fakarava-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Fakarava
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/french-polynesia-diving/moorea-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Moorea
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/french-polynesia-diving/rangiroa-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Rangiroa
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/micronesia-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Micronesia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Micronesia Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving/bikini-atoll-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Bikini Atoll
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving/kosrae-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Kosrae
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving/palau-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Palau
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving/truk-lagoon-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Truk Lagoon
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/micronesia-diving/yap-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Yap
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%]  w-[80%] mx-auto">
                            <Link href={"/destination/australia-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Australia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/australia-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Australia Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/australia-diving/great-barrier-reef-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Great Barrier Reef
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/papua-new-guinea-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Papua New Guinea
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/solomon-islands-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Solomon Islands
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/tonga-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Tonga
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/vanuatu-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Vanuatu
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length > 1 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[1]?.image)? destinationMenus[1]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[1]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[1]?.short_description}}>

                              </p>
                              <Link
                                  href={(destinationMenus[1]?.link_page) ? destinationMenus[1]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <ul className="">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-in-caribbean"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Caribbean Highlights
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/aruba-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Aruba
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/bahamas-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Bahamas
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/carribbean/tiger-beach-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Tiger Beach
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/belize-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Belize
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/bonaire-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Bonaire
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/british-virgin-islands-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                British Virgin Islands
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/cayman-islands-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Cayman Islands
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/cuba-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Cuba
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/carribbean/jardines-de-la-reina"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Jardines De La Reina
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/curacao-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Curacao
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/dominican-republic-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Dominican Republic
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/dominican-republic-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Dominica
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/diving-jamaica"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Jamaica
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/roatan-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Roatan, Utila & Guanaja
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/saba-st-kitts-st-eustatius"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                               St. Kitts & St. Eustatius
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/scuba-diving-st-lucia"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                St. Lucia
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/st-vincent-and-grenadines-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                St. Vincent and the Grenadines
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/diving-st-maarten"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                St. Maarten
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/st-thomas-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                St. Thomas
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/trinidad-tobago-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Trinidad & Tobago
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/carribbean/turks-and-caicos-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Turks and Caicos
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length > 2 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[2]?.image)? destinationMenus[2]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[2]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[2]?.short_description}}>

                              </p>
                              <Link
                                  href={(destinationMenus[2]?.link_page) ? destinationMenus[2]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 3 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/mexico"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Mexico
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-mexico"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Mexico Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/baja-california"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Baja California
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/cabo-san-lucas-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cabo San Lucas
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/cancun"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cancun
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/cenotes-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cenotes
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/cozumel-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cozumel
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/guadalupe-island-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Guadalupe Island
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/isla-mujeres-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Isla Mujeres
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/la-paz-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  La Paz
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/san-ignacio-gray-whales"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  San Ignacio Gray Whales
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/sea-of-cortez-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Sea Of Cortez
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/mexico/socorro-island-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Socorro Islands
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/socorro-island-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Socorro Islands
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/galapagos-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Galapagos
                              </span>
                            </Link>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/usa"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                USA
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-usa"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  USA Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/usa/alaska-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Alaska
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/usa/catalina-island-scuba-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Catalina Island
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/usa/florida-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Florida
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/usa/Diving-in-Southern-California"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Southern California
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/costa-rica-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Costa Rica
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/costa-rica-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Costa Rica Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/costa-rica-diving/cocos-island-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Cocos Island
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/best-diving-in-hawaii"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Hawaii
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-hawaii/kauai-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Kauai
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-hawaii/kona-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Kona
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/best-diving-in-hawaii/maui-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Maui
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/colombia"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Colombia
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/colombia/malpelo-island-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Malpelo
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length > 3 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[3]?.image)? destinationMenus[3]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[3]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[3]?.short_description}}>

                              </p>
                              <Link
                                  href={(destinationMenus[3]?.link_page) ? destinationMenus[3]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 4 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/indian-ocean"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Indian Ocean
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indian-ocean/andaman-islands-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Andaman Islands
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indian-ocean/maldives-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Maldives
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indian-ocean/mauritius-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Mauritius
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indian-ocean/seychelles-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Seychelles
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/indian-ocean/diving-sri-lanka"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Sri Lanka
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/africa"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Africa - Diving
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/article/best-diving-africa"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Africa Highlights
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa/madagascar-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Madagascar
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa/mozambique-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Mozambique
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa/south-africa-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  South Africa
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa/diving-zanzibar-tanzania-kenya"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Zanzibar, Tanzania & Kenya
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/africa-safaris"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Africa - Safaris
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa-safaris/gorilla-trekking"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Gorilla Trekking
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/africa-safaris/tanzania-safaris"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Tanzania Safaris
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/red-sea-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Red Sea & Middle East
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/red-sea-diving/oman-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Oman
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/red-sea-diving/red-sea-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Red Sea
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/red-sea-diving/scuba-diving-in-saudi-arabia"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Saudi Arabia
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/red-sea-diving/sudan-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Sudan
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/red-sea-diving/scuba-diving-turkey"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Turkey
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length > 4 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[4]?.image)? destinationMenus[4]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[4]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[4]?.short_description}}>

                              </p>
                              <Link
                                  href={(destinationMenus[4]?.link_page) ? destinationMenus[4]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`768-991:w-full w-9/12 tab-pane pt-5 overflow-y-auto scrollbar-hidden ${
                    activeTab === 5 ? "active" : ""
                  }`}
                >
                  <div className="flex 768-991:flex-wrap justify-between">
                    <div className="768-991:w-full w-9/12">
                      <div className="flex flex-wrap">
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/europe"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Europe
                              </span>
                            </Link>
                            <ul className="pt-3">
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/europe/azores-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Azores
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/europe/scuba-diving-in-iceland"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Iceland
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/europe/malta-diving"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Malta
                                </Link>
                              </li>
                              <li onClick={handleLinkClick}>
                                <Link
                                  href={"/destination/europe/norway-dive-travel-guide"}
                                  className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                >
                                  Norway
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="768-991:w-full w-4/12">
                          <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-4 w-[80%] mx-auto">
                            <Link href={"/destination/antarctica-diving"}>
                              <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                                Antarctica
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="768-991:w-full w-3/12">
                      {(destinationMenus?.length === 6 ) && (
                          <div className="dropdown-image 768-991:w-[50%] relative">
                            <Image
                                src={(destinationMenus[5]?.image)? destinationMenus[5]?.image : mega1}
                                width={100}
                                height={100}
                                alt={`image`}
                                className={`absolute top-0 left-0 w-full h-full object-cover pointer-events-none rounded-[10px]`}
                            />
                            <div className="dropdown-image-overlay absolute top-0 left-0 w-full h-full bg-black z-[5] opacity-[0.4] pointer-events-none rounded-[10px]"></div>
                            <div
                                className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                            >
                          <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                            {destinationMenus[5]?.name}
                          </span>
                              <p className="font-poppins-regular  text-[13px] line-clamp-6 leading-[18px] tracking-[0.26px] text-white mb-2" dangerouslySetInnerHTML={{__html:destinationMenus[5]?.short_description}}>

                              </p>
                              <Link
                                  href={(destinationMenus[5]?.link_page) ? destinationMenus[5]?.link_page:"javascript:void(0)"}
                                  className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                              >
                                Read more
                              </Link>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DestinationsDropdown;
