import Image from "next/image";
import Link from "next/link";
import cc1 from "/assets/images/contactUs/cc1.jpg";
import cc2 from "/assets/images/contactUs/cc2.jpg";
import cc3 from "/assets/images/contactUs/cc3.jpg";
import cc4 from "/assets/images/contactUs/cc4.jpg";
import logo from "/assets/images/logo.svg";
import logoBlue from "/assets/images/logo2.svg";
import start from "/assets/images/star.svg";
import angleDown from "/assets/images/angleDown.svg";
import chatIcon from "/assets/images/chatIcon.svg";
import phoneIcon from "/assets/images/phoneIcon.svg";
import whatsappIcon from "/assets/images/whatsapp.png";
import searchWhite from "/assets/images/searchWhite.svg";
import searchBlue from "/assets/images/searchBlue.svg";
import searchIcon from "/assets/images/search.svg";
import hamburger from "/assets/images/hamburger.png";
import hamburgerBlue from "/assets/images/hamburger-blue.png";
import crossBlue from "/assets/images/cross.svg";
import angleDownWhite from "/assets/images/angleDownWhite.svg";

import DestinationsDropdown from "../destination-dropdown";
import ContactUsDropdown from "../contact-dropdown";
import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import LiveaboardDropdown from "../liveaboard-dropdown";
import ResortsDropdown from "../resorts-dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSignedIn,
  selectUserData,
  setSignedIn,
  setUserData,
} from "../../redux/reducer/userSlice";
import { getUser, logout } from "../../services/login";
import { useHeader } from "../../hooks/useHeader";
import GuidedDropdown from "../guided-dropdown";
import IdeasDropdown from "../ideas-dropdown";
import SpecialListDropdown from "../special-list-dropdown";
import SearchBar from "../search-bar";
export default function Header() {
  const router = useRouter();
  const [
    dropdownDestinationValues,
    liveaboardValues,
    resortValues,
    guidedGroupTrips,
  ] = useHeader();
  const [search, setSearch] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [contactDropdown, setContactDropdown] = useState(false);
  const [destination, setDestination] = useState(false);
  const [liveaboard, setLiveaboard] = useState(false);
  const [resorts, setResorts] = useState(false);
  const [dynamicLogo, setLogo] = useState(logo);
  const [headerBG, setHeaderBG] = useState(false);
  const [headerBG2, setHeaderBG2] = useState(false);
  const [headerBG3, setHeaderBG3] = useState(false);
  const [headerBG4, setHeaderBG4] = useState(false);
  const [headerBG5, setHeaderBG5] = useState(false);
  const [headerBG6, setHeaderBG6] = useState(false);
  const [isBlue, setIsBlue] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [toggleSub, setToggleSub] = useState(false);
  const [tabIndex, setTabIndex] = useState(null);
  const [tabClicked, setTabClicked] = useState(false);
  const [tabname, setTabName] = useState("");
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const isSignedIn = useSelector(selectSignedIn);
  const checkUserExist = async () => {
    let appToken = localStorage.getItem("access_token");
    if (appToken != "" && !isSignedIn) {
      let user = await getUser();
      if (user?.status === "success") {
        dispatch(setUserData(user?.data));
        dispatch(setSignedIn(true));
      } else {
        localStorage.setItem("access_token", "");
        dispatch(setSignedIn(false));
        dispatch(setUserData(""));
      }
    }
  };
  const userLogout = async () => {
    let res = await logout();
    if (res?.status === "success") {
      localStorage.setItem("access_token", "");
      dispatch(setSignedIn(false));
      dispatch(setUserData(""));
      router.push("/login");
    } else {
      localStorage.setItem("access_token", "");
      dispatch(setSignedIn(false));
      dispatch(setUserData(""));
      router.push("/login");
    }
  };
  const tabsRef = useRef([]);
  const tabrefMain = useRef();
  const handleTabClick = (index) => {

    tabrefMain.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => { tabsRef.current[index].scrollIntoView({ behavior: 'smooth' }) }, 100)
    const prevIndex = activeTab;
    toggleSub
      ? setActiveTab(index === prevIndex ? null : index)
      : setActiveTab(index);
    setToggleSub((prevToggleSub) => !prevToggleSub);
  };

  useEffect(() => {
    if (
      router?.pathname === "/liveaboard-destination/[...index]" ||
      router?.pathname === "/liveaboard/[...index]" ||
      router?.pathname === "/article/[...index]" ||
      router?.pathname === "/resort/[...index]" ||
      router?.pathname === "/resort-destination/[...index]" ||
      router?.pathname === "/team/[index]" ||
      router?.pathname === "/dive-operator/[index]" ||
      router?.pathname === "/guided-group-trips" ||
      router?.pathname === "/my-profile" ||
      router?.pathname === "/destination/[...index]" ||
      router?.pathname === "/terms-conditions" ||
      router.pathname === "/sub-destination/[...index]" ||
      router.pathname === "/ideas/[...index]" ||
      router.pathname === "/thank-you" ||
      router.pathname === "/team" ||
      router.pathname === "/booking-form-resort/[index]" ||
      router.pathname === "/booking-form-liveaboard/[index]" ||
      router.pathname === "/about-us" ||
      router.pathname === "/group-trip/[index]" ||
      router.pathname === "/all-liveaboard" ||
      router.pathname === "/all-resorts"
    ) {
      setLogo(logoBlue);
      setIsBlue(true);
      setIsActive(true);
      setName(router?.pathname);
    }
    if (router?.pathname === "/specials-list") {
      setIsActive(true);
      setName(router?.pathname);
    }
    checkUserExist();
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false);
    });
    setNavigation(false);
  }, [router]);

  useEffect(() => {
    headerBG
      ? document.body.classList.add("h-[100vh]", "overflow-y-hidden")
      : headerBG2
        ? document.body.classList.add("h-[100vh]", "overflow-y-hidden")
        : headerBG3
          ? document.body.classList.add("h-[100vh]", "overflow-y-hidden")
          : navigation
            ? document.body.classList.add("h-[100vh]", "overflow-y-hidden")
            : document.body.classList.remove("h-[100vh]", "overflow-y-hidden");
  }, [headerBG, headerBG2, headerBG3, navigation]);

  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState("");

  const handleHamburger = () => {
    setNavigation(!navigation);
  };

  const handleCloseHamburger = () => {
    setTimeout(() => {
      setNavigation(false);
    }, 1000)
  }

  const handleContact = () => {
    setContactDropdown(!contactDropdown);
  };

  const handleDestination = () => {
    setDestination(!destination);
    setLiveaboard(false);
    setResorts(false);
  };
  const handleLiveaboard = () => {
    setLiveaboard(!liveaboard);
    setDestination(false);
    setResorts(false);
  };

  const handleResorts = () => {
    setResorts(!resorts);
    setDestination(false);
    setLiveaboard(false);
  };

  const handleCloseSearch = () => {
    setSearch(false);
  }

  const destinationOutside = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (destinationOutside.current && !destinationOutside.current.contains(event.target)) {
        setDestination(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  })

  const liveaboardOutside = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (liveaboardOutside.current && !liveaboardOutside.current.contains(event.target)) {
        setLiveaboard(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  })

  const resortOutside = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (resortOutside.current && !resortOutside.current.contains(event.target)) {
        setResorts(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  })

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const toggleHeaderBG = (headerIndex) => {
    const headers = [setHeaderBG, setHeaderBG2, setHeaderBG3, setHeaderBG4, setHeaderBG5, setHeaderBG6];

    headers.forEach((setHeader, index) => {
      setHeader(index === headerIndex ? prevState => !prevState : false);
    });
  };

  const closeHeader = (headerIndex) => {
    const headers = [setHeaderBG, setHeaderBG2, setHeaderBG3, setHeaderBG4, setHeaderBG5, setHeaderBG6];

    headers[headerIndex](false);
  };

  const setAllFalse = () => {
    setHeaderBG(false);
    setHeaderBG2(false);
    setHeaderBG3(false);
    setHeaderBG4(false);
    setHeaderBG5(false);
    setHeaderBG6(false);
  };

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );


  useEffect(() => {
    const handleWindowResize = () => {
      const newWindowWidth = window.innerWidth;
      setWindowWidth(newWindowWidth);

      if (newWindowWidth >= 992) {
        setNavigation(false);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleWindowResize);
      }
    };
  }, []);

  return (
    <>
      <header
        id={"header"}
        className={`header ${headerBG
            ? "bg-[#F8FCFF] header-1"
            : headerBG2
              ? "bg-[#F8FCFF] header-2"
              : headerBG3
                ? "bg-[#F8FCFF] header-3"
                : ""
          } ${navigation
            ? "fixed"
            : scrolled
              ? "fixed bg-[#fff] shadow-header-shadow items-center"
              : "absolute h-[100px] items-end"
          } text-black 2560:px-[390px] top-0 left-0 w-full 320-529:ps-5 768-991:items-center 768-991:justify-between 768-991:py-4 768-991:pe-5 z-50 flex transition-all duration-600 ease-in-out`}
      >
        <div
          className={`logo ${scrolled ? "" : "pb-[10px] 768-991:pb-0"
            } 768-991:ps-0 768-991:pe-0 2560:ps-[3rem] ps-[5rem] pe-[14rem] 1680-1919:pe-[12rem] 1600-1679:pe-[7rem] 1600-1679:ps-[4rem] 1440-1599:pe-[6rem] 1440-1599:ps-[3rem] 1366-1439:pe-[5rem] 1366-1439:ps-[2rem] 992-1199:pe-[2rem] 992-1199:ps-[1rem] relative`}
        >
          <Link href="/">
            <Image
              src={
                navigation
                  ? logoBlue
                  : headerBG
                    ? logoBlue
                    : headerBG2
                      ? logoBlue
                      : headerBG3
                        ? logoBlue
                        : scrolled
                          ? logoBlue
                          : dynamicLogo
              }
              alt="Vercel Logo"
              className={`320-529:w-[150px] 992-1199:h-[45px] 768-991:h-[45px]`}
              width={250}
              height={66}
              priority
              onClick={() => {
                setNavigation(false);
              }}
            />
          </Link>
        </div>
        <div
          className={`w-full h-full ${scrolled ? "bg-transparent" : "bg-white shadow-header-shadow"
            } rounded-bl-[20px] font-poppins-semiBold 768-991:hidden`}
        >
          <div
            className={`${scrolled && "hidden"
              } bg-[#D6E8EF] flex items-center gap-2 1440-1599:w-[21rem] 1600-1679:w-[21rem] w-[22rem] 992-1199:w-72 ms-auto p-2 ps-4 rounded-bl-[10px] h-[35%]`}
          >
            <Link
              href="/about-us"
              className="text-xs 992-1199:text-[10px] font-semibold tracking-[0.6px] hover:text-[#3DABC2]"
            >
              ABOUT US
            </Link>
            <Image
              src={start}
              alt="Vercel Logo"
              width={10}
              height={10}
              priority
            />
            {!isSignedIn ? (
              <Link
                href="/login"
                className="text-xs 992-1199:text-[10px] font-semibold tracking-[0.6px] hover:text-[#3DABC2]"
              >
                LOGIN
              </Link>
            ) : (
              <Link
                href="javascript:void(0)"
                className="text-xs 992-1199:text-[10px] font-semibold tracking-[0.6px] hover:text-[#3DABC2]"
                onClick={userLogout}
              >
                LOGOUT
              </Link>
            )}
            {isSignedIn && (
              <>
                <Image
                  src={start}
                  alt="Vercel Logo"
                  width={10}
                  height={10}
                  priority
                />
                <Link
                  href="/my-profile"
                  className="text-xs 992-1199:text-[10px] font-semibold tracking-[0.6px] hover:text-[#3DABC2]"
                >
                  PROFILE
                </Link>
              </>
            )}
          </div>
          <div
            className={`flex items-center ${scrolled ? "h-full justify-end py-[15px]" : "h-[65%]"
              } px-8 992-1199:px-3 1366-1439:px-5`}
          >
            <ul className="flex w-full items-center justify-between 992-1199:text-[12px]  text-lg 1200-1300:text-[13px] 1440-1599:text-[15px] 1366-1439:text-[15px]">
              <DestinationsDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef}
                openState={headerBG}
                onClick={() => toggleHeaderBG(0)}
                destinationMenus={dropdownDestinationValues}
                closeDestination={closeHeader}
              />
              <LiveaboardDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef2}
                openState={headerBG2}
                onClick={() => toggleHeaderBG(1)}
                liveboardMenus={liveaboardValues}
                handleCloseHamburger={handleCloseHamburger}
                closeLiveaboard={closeHeader}
              />
              <ResortsDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef3}
                openState={headerBG3}
                onClick={() => toggleHeaderBG(2)}
                resortMenus={resortValues}
                closeResort={closeHeader}
              />
              <GuidedDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef4}
                openState={headerBG4}
                onClick={() => toggleHeaderBG(3)}
                guidedGroupTrips={closeHeader}
              />
              <IdeasDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef5}
                openState={headerBG5}
                onClick={() => toggleHeaderBG(4)}
              />
              <SpecialListDropdown
                setAllFalse={setAllFalse}
                dropdownRef={dropdownRef6}
                openState={headerBG6}
                onClick={() => toggleHeaderBG(5)}
              />

              <li>
                <Link href="/search" className="open-search group">
                  <Image
                    src={searchIcon}
                    alt="Vercel Logo"
                    width={20}
                    height={20}
                    priority
                  />
                </Link>
              </li>
              <ContactUsDropdown />
            </ul>
          </div>
        </div>
        <div className="hidden 768-991:flex items-center gap-6">
          <Link href={"/search"}>
            <Image
              src={
                navigation
                  ? searchBlue
                  : isBlue
                    ? searchBlue
                    : scrolled
                      ? searchBlue
                      : searchWhite
              }
              alt="Vercel Logo"
              width={20}
              height={20}
            />
          </Link>
          <Image
            src={
              navigation
                ? crossBlue
                : isBlue
                  ? hamburgerBlue
                  : scrolled
                    ? hamburgerBlue
                    : hamburger
            }
            alt="Vercel Logo"
            onClick={() => {
              handleHamburger();
            }}
            width={32}
            height={32}
          />
        </div>
        <div
          id="dropdown-menu"
          className="opacity-0 pointer-events-none absolute top-[95px] 992-1199:px-1 left-0 -z-[1] w-full transition-all duration-600 ease-in-out origin-top-right bg-[#f8fcff] shadow-lg focus:outline-none px-28 1366-1439:px-20 pb-10"
        >
          <div className="flex flex-wrap">
            <div className="w-full md:w-3/12">
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[22px] tracking-[-0.18px]">
                  Asia Pacific
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Australia
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Fiji
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      French Polynesia
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Marshal Islands
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Micronesia
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Palau
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Papua New Guinea
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Solomon Islands
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Tonga
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  Caribbean
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Bahamas
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      British Virgin Islands
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Cayman Islands
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Cuba
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Honduras (Roatan)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      St. Kitts & Nevis
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      St. Maarten
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Turks & Caicos
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-3/12">
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  Central & South America
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Argentina
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Belize
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Colombia (Malpelo)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Costa Rica - Cocos
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Costa Rica - Pacific coast
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Ecuador (Galapagos)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Mexico - Guadalupe Island
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Mexico - Sea of Cortez
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Mexico - Socorro
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Panama (Malpelo)
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  Europe
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Greece
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Italy
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Norway
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Portugal
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Spain
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Turkey
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-3/12">
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  Indian Ocean
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Maldives
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Seychelles
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  Middle East & Red Sea
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Djibouti
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Egypt
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Oman
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Saudi Arabia
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Sudan
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  North America
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Hawaii
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-3/12">
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 border-b border-solid border-[#D6E8EF] w-[50%] mx-auto">
                <span className="font-poppins-semiBold text-lg  leading-[21px] tracking-[-0.18px]">
                  South-East Asia
                </span>
                <ul className="pt-4">
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Indonesia - Komodo
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Indonesia - Raja Ampat
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Malaysia (Sipadan)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Myanmar (Burma)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Philippines - Tubbatha reef
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Philippines
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="javascript:void(0)"
                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                    >
                      Thailand (Similan)
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown-links 1260-1365:w-[70%] 992-1199:w-[70%] pt-10 pb-5 w-[50%] mx-auto">
                <Link
                  href="javascript:void(0)"
                  className="font-poppins-semiBold text-sm  leading-6 p-3 px-6 rounded-[100px] bg-[#3DABC2] text-white"
                >
                  Entire world
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mobile-header"
          className={`mobile-header pt-24 px-6 w-full h-[100vh] fixed top-0 left-0 bg-white -z-[1] ${navigation
              ? "opacity-1 pointer-events-auto"
              : "opacity-0 pointer-events-none"
            }  transition-all duration-600 ease-in-out`}
        >
          <div className="flex items-center justify-between 320-425:flex-col 320-425:gap-6 320-350:flex-col 320-350:gap-6  py-6">
            <div className="flex items-center gap-2">
              <Link
                href="/about-us"
                className="text-[13px] font-poppins-semiBold "
              >
                ABOUT US
              </Link>
              <Image
                src={start}
                width={10}
                height={10}
                alt="image"
                title="image"
              />
              {!isSignedIn ? (
                <Link
                  href="/login"
                  className="text-[13px]  font-poppins-semiBold tracking-[0.6px] hover:text-[#3DABC2]"
                >
                  LOGIN
                </Link>
              ) : (
                <Link
                  href="javascript:void(0)"
                  className="text-[13px]  font-poppins-semiBold tracking-[0.6px] hover:text-[#3DABC2]"
                  onClick={userLogout}
                >
                  LOGOUT
                </Link>
              )}
              {isSignedIn && (
                <>
                  <Image
                    src={start}
                    alt="Vercel Logo"
                    width={10}
                    height={10}
                    priority
                  />
                  <Link
                    href="/my-profile"
                    className="text-[13px]  font-poppins-semiBold tracking-[0.6px] hover:text-[#3DABC2]"
                  >
                    PROFILE
                  </Link>
                </>
              )}
            </div>
            <div className="relative">
              <div
                id="dropdown-button4"
                className="bg-[#F57415] font-poppins-semiBold py-3.5 px-6  text-sm 992-1199:py-3 992-1199:px-5 992-1199:text-[12px] text-white rounded-full flex items-center gap-3 relative"
              >
                <Link href="/contact-us">Contact us</Link>
                <span className="block w-[1px] h-[20px] bg-[#D85C02]"></span>
                <Image src={angleDownWhite} alt="image" className={`${contactDropdown && "rotate-[180deg]"}`} title="image" onClick={() => {
                  handleContact();
                }} />
              </div>
              <div
                id="dropdown-menu4"
                className={`${contactDropdown
                    ? "opacity-1 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
                  }  border 320-350:-right-[53%] 320-425:-right-[53%] border-solid border-[#B6D6E2] absolute top-[50px] rounded-bl-[10px] rounded-br-[10px] right-0 z-10 w-[330px] transition-all duration-600 ease-in-out origin-top-right bg-white focus:outline-none py-7 pb-5 px-3`}
              >
                <span className="font-poppins-semiBold text-lg  leading-[22px] tracking-[-0.18px] text-center text-black block">
                  Contact us
                </span>
                <div className="flex -space-x-2 justify-center py-4">
                  <Image
                    src={cc1}
                    alt="Vercel Logo"
                    className="inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-avatar"
                  />
                  <Image
                    src={cc2}
                    alt="Vercel Logo"
                    className="inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-avatar"
                  />
                  <Image
                    src={cc3}
                    alt="Vercel Logo"
                    className="inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-avatar"
                  />
                  <Image
                    src={cc4}
                    alt="Vercel Logo"
                    className="inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover shadow-avatar"
                  />
                </div>
                <p className="font-poppins-regular text-[13px]  leading-[18px] tracking-[0.26px] text-center text-[#4A748B] pt-2">
                  Our travel consultants are available around the clock to help
                  you plan your next trip.
                </p>
                <div className="flex items-center justify-center gap-2 border-b border-solid border-[#D6E8EF] py-3 pb-4">
                  <Image
                    src={chatIcon}
                    alt="Vercel Logo"
                    width={21}
                    height={18}
                    priority
                  />
                  <span className="text-[13px] text-black font-poppins-semiBold  leading-[24px] tracking-[0.13px]">
                    Chat with us live
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2 py-4 pb-1">
                  <Image
                    src={phoneIcon}
                    alt="Vercel Logo"
                    width={16}
                    height={17}
                    priority
                  />
                  <Link
                    href="tel:310-915-6677"
                    className="320-529:flex 320-529:gap-[10px]  text-[14px] text-[#3DABC2] font-poppins-semiBold  leading-[24px] tracking-[4.2px]"
                  >
                    310-915-6677
                  </Link>
                </div>
                <div className="flex items-center justify-center gap-2 py-4 pb-1">
                  <Image
                    src={whatsappIcon}
                    alt="WhatsApp Logo"
                    width={16}
                    height={17}
                    priority
                  />
                  <Link
                    href="tel:310-915-3222"
                    className="text-[14px] text-[#3DABC2] font-poppins-semiBold  leading-[24px] tracking-[4.2px]"
                  >
                    310-915-3222
                  </Link>
                </div>
                <Link
                  href="mailto:info@bluewaterdivetravel.com"
                  className="text-[14px] text-[#3DABC2] font-poppins-semiBold  leading-[24px] tracking-[1.12px] py-3 pb-1 text-center block"
                >
                  info@bluewaterdivetravel.com
                </Link>
              </div>
            </div>
          </div>
          <ul className="flex flex-col items-center overflow-y-auto max-h-[450px]  border-t border-solid border-[#B6D6E2] font-poppins-semiBold 1680-1919:gap-10 1600-1679:gap-5 1440-1599:gap-6 992-1199:justify-center 992-1199:gap-2 992-1199:text-[12px] 1366-1439:gap-8 1260-1365:gap-5  text-lg 1440-1599:text-[15px] 1366-1439:text-[15px]">
            <li
              className="border-b border-solid border-[#B6D6E2] w-full"
              id="dropdown-button5"
              ref={destinationOutside}
            >
              <div className="flex items-center gap-2" >
                <Link
                  href="javascript:void(0)"
                  onClick={() => {
                    handleDestination();
                  }}
                  className={`w-full py-5 flex items-center group gap-2 992-1199:gap-1 ${router?.pathname === "/destination/[...index]" || router?.pathname === "/sub-destination/[...index]" ? "!text-[#F57415] btn-orange" : destination ? "!text-[#F57415] btn-orange" : ""}`}
                >
                  Destinations
                  <Image
                    src={angleDown}
                    alt="Vercel Logo"
                    className={`transform ${destination ? "rotate-180" : ""
                      } cursor-pointer`}
                    width={10}
                    height={6}
                    priority
                  />
                </Link>

              </div>
              <div
                id="dropdown-menu5"
                className={`opacity-1 ${destination
                    ? "pointer-events-auto"
                    : "hidden pointer-events-none"
                  } w-full h-[40vh] overflow-y-auto mt-[20px] transition-all duration-600 ease-in-out origin-top-right bg-[#fff] focus:outline-none 768-991:pb-1 pb-10`}
              >
                <div className="flex 768-991:flex-wrap 768-991:pt-1 pt-10">
                  <div className="768-991:w-full w-3/12 768-991:pb-1 pb-5">
                    <ul ref={tabrefMain} className="tab-list w-[90%] 768-991:w-full font-poppins-semiBold  text-[18px] 992-1023:w-full 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] tracking-[-0.18px] border-r border-solid border-[#E4F4FB]">
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 0
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(0)}
                        ref={el => (tabsRef.current[0] = el)}
                      >
                        Southeast Asia
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 0 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/southeast-asia-highlights"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Southeast Asia Highlights
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">  
                                <Link href={"/destination/indonesia"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Indonesia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-in-indonesia"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Indonesia Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/Alor-Diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Alor
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/ambon-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Ambon
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/bali-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Bali
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/banda-sea-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Banda Sea
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/cenderawasih-bay-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cenderawasih Bay
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/derawan-islands-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Derawan Islands
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/komodo-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Komodo
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/lembeh-strait-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Lembeh Strait
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/lombok-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Lombok
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/manado-bunaken-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Manado And Bunaken
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/raja-ampat-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Raja Ampat
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indonesia/wakatobi-national-park-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Wakatobi National Park
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%]  w-[80%] mx-auto">
                                <Link href={"/destination/philippines"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Philippines
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-in-philippines"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Philippines Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/anilao-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Anilao
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/cebu-bohol-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cebu And Bohol
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/coron-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Coron
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/dumaguete-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Dumaguete
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/leyte-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Leyte
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/malapascua-island-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Malapascua Island
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/puerto-galera-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Puerto Galera
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/romblon-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Romblon
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/philippines/tubbataha-reef-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Tubbataha
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pt-5 w-[80%] mx-auto">
                                <Link href={"/destination/best-diving-in-thailand"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Thailand
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/destination/best-diving-in-thailand"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Thailand Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/best-diving-in-thailand/phuket-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Phuket
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/best-diving-in-thailand/similan-islands-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Similan Islands
                                    </Link>
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/borneo-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Borneo
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/malaysia-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Malaysia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                      <Link
                                        href={"/sub-destination/malaysia-diving/sipadan-diving"}
                                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                      >
                                        Sipadan
                                      </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/myanmar-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Myanmar
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 1
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(1)}
                        ref={el => (tabsRef.current[1] = el)}
                      >
                        Asia Pacific
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 1 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/fiji-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Fiji
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/french-polynesia-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    French Polynesia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/destination/french-polynesia-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      French Polynesia Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/french-polynesia-diving/fakarava-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Fakarava
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/french-polynesia-diving/moorea-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Moorea
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/french-polynesia-diving/rangiroa-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Rangiroa
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/micronesia-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Micronesia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/destination/micronesia-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Micronesia Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/micronesia-diving/bikini-atoll-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Bikini Atoll
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/micronesia-diving/kosrae-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Kosrae
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/micronesia-diving/palau-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Palau
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/micronesia-diving/truk-lagoon-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Truk Lagoon
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/micronesia-diving/yap-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Yap
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/australia-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Australia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/destination/australia-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Australia Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/australia-diving/great-barrier-reef-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Great Barrier Reef
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/papua-new-guinea-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Papua New Guinea
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/solomon-islands-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Solomon Islands
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/tonga-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Tonga
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/vanuatu-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Vanuatu
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 2
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(2)}
                        ref={el => (tabsRef.current[2] = el)}
                      >
                        Caribbean
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 2 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-in-caribbean"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Caribbean Highlights
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/aruba-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Aruba
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/bahamas-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Bahamas
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/carribbean/tiger-beach-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Tiger Beach
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/belize-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Belize
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/bonaire-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Bonaire
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/british-virgin-islands-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    British Virgin Islands
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/cayman-islands-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Cayman Islands
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/Cuba-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Cuba
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/carribbean/jardines-de-la-reina"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Jardines De La Reina
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/curacao-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Curacao
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/dominican-republic-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Dominican Republic
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/dominican-republic-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Dominica
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/diving-jamaica"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Jamaica
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/roatan-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Roatan, Utila & Guanaja
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/saba-st-kitts-st-eustatius"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    St. Kitts & St. Eustatius
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/scuba-diving-st-lucia"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    St. Lucia
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/st-vincent-and-grenadines-diving"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    St. Vincent and the Grenadines
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/diving-st-maarten"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    St. Maarten
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/st-thomas-diving"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    St. Thomas
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/trinidad-tobago-diving"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Trinidad & Tobago
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/sub-destination/carribbean/turks-and-caicos-diving"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Turks and Caicos
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 3
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(3)}
                        ref={el => (tabsRef.current[3] = el)}
                      >
                        The Americas
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 3 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/mexico"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Mexico
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-mexico"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Mexico Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/baja-california"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Baja California
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/cabo-san-lucas-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cabo San Lucas
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/cancun"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cancun
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/cenotes-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cenotes
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/cozumel-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cozumel
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/guadalupe-island-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Guadalupe Island
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/isla-mujeres-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Isla Mujeres
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/la-paz-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      La Paz
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/san-ignacio-gray-whales"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      San Ignacio Gray Whales
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/sea-of-cortez-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Sea Of Cortez
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/mexico/socorro-island-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Socorro Islands
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/socorro-island-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Socorro Islands
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/galapagos-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Galapagos
                                  </span>
                                </Link>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/usa"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    USA
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-usa"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      USA Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/usa/alaska-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Alaska
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/usa/catalina-island-scuba-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Catalina Island
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/usa/florida-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Florida
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/usa/Diving-in-Southern-California"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Southern California
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/costa-rica-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Costa Rica
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/destination/costa-rica-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Costa Rica Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/costa-rica-diving/cocos-island-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Cocos Island
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/best-diving-in-hawaii"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Hawai
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/best-diving-in-hawaii/kauai-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Kauai
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/best-diving-in-hawaii/kona-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Kona
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/best-diving-in-hawaii/maui-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Maui
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/colombia"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Colombia
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/colombia/malpelo-island-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Malpelo
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 4
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(4)}
                        ref={el => (tabsRef.current[4] = el)}
                      >
                        Indian Ocean, Africa & Middle East
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 4 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/indian-ocean"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Indian Ocean
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indian-ocean/andaman-islands-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Andaman Islands
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indian-ocean/maldives-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Maldives
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indian-ocean/mauritius-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Mauritius
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indian-ocean/seychelles-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Seychelles
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/indian-ocean/diving-sri-lanka"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Sri Lanka
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/africa"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Africa - Diving
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/article/best-diving-africa"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Africa Highlights
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa/madagascar-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Madagascar
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa/mozambique-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Mozambique
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa/south-africa-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      South Africa
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa/diving-zanzibar-tanzania-kenya"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Zanzibar, Tanzania & Kenya
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/africa-safaris"}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Africa - Safaris
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa-safaris/gorilla-trekking"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Gorilla Trekking
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/africa-safaris/tanzania-safaris"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Tanzania Safaris
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/red-sea-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Red Sea & Middle East
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/red-sea-diving/oman-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Oman
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/red-sea-diving/red-sea-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Red Sea
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/red-sea-diving/scuba-diving-in-saudi-arabia"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Saudi Arabia
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/red-sea-diving/sudan-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Sudan
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/red-sea-diving/scuba-diving-turkey"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Turkey
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className={`w-full py-5 ps-5 pe-14 1440-1599:pe-10 768-991:pe-5 rounded-[8px]  cursor-pointer transition-all duration-600 ease-in-out ${activeTab === 5
                            ? "active text-[#3DABC2] bg-[#e4f4fb]"
                            : "text-black"
                          }`}
                        onClick={() => handleTabClick(5)}
                        ref={el => (tabsRef.current[5] = el)}
                      >
                        Europe & the Polar Regions
                        <div
                          className={`768-991:w-full w-9/12 tab-pane pt-5 ${activeTab === 5 ? "active" : ""
                            }`}
                        >
                          <div className="flex 768-991:flex-wrap">
                            <div className="768-991:w-full w-3/12">
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/europe"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Europe
                                  </span>
                                </Link>
                                <ul className="pt-4">
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/europe/azores-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Azores
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/europe/scuba-diving-in-iceland"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Iceland
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/europe/malta-diving"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Malta
                                    </Link>
                                  </li>
                                  <li onClick={handleCloseHamburger}>
                                    <Link
                                      href={"/sub-destination/europe/norway-dive-travel-guide"}
                                      className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                                    >
                                      Norway
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-links 768-991:w-full 1260-1365:w-[70%] 992-1199:w-[70%] pb-5 w-[80%] mx-auto">
                                <Link href={"/destination/antarctica-diving"} onClick={handleCloseHamburger}>
                                  <span className="font-poppins-semiBold text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-black hover:text-[#F57415] cursor-pointer">
                                    Antarctica
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li className="border-b border-solid border-[#B6D6E2] w-full">
              <div className="flex items-center gap-2" ref={liveaboardOutside}>
                <Link
                  href="javascript:void(0)"
                  onClick={() => {
                    handleLiveaboard();
                  }}
                  className={`w-full py-5 flex items-center group gap-2 992-1199:gap-1 ${router?.pathname === "/liveaboard/[...index]" ? "!text-[#F57415] btn-orange" : liveaboard ? "!text-[#F57415] btn-orange" : ""} `}
                >
                  Liveaboards
                  <Image
                    src={angleDown}
                    alt="Vercel Logo"
                    className={`transform ${liveaboard ? "rotate-180" : ""
                      } cursor-pointer`}
                    width={10}
                    height={6}
                    priority
                  />
                </Link>

              </div>
              <div
                id="dropdown-menu5"
                className={`opacity-1 ${liveaboard
                    ? "pointer-events-auto"
                    : "hidden pointer-events-none"
                  } w-full h-[40vh] overflow-y-auto mt-[20px] transition-all duration-600 ease-in-out origin-top-right bg-[#fff] focus:outline-none 768-991:pb-1 pb-10`}
              >
                <div className="flex flex-wrap 768-991:flex-wrap pt-5">
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Southeast Asia
                      </h3>

                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/all-liveaboard"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            All Liveaboards
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/indonesia-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                              Indonesia
                          </Link>
                        </li>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/indonesia-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Indonesia
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/komodo-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Komodo
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/raja-ampat-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Raja Ampat
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/philippines-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Philippines
                          </Link>
                        </li>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/philippines-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Philippines
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/tubbataha-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Tubbataha
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/thailand-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Thailand
                          </Link>
                        </li>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/thailand-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Thailand
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/similan-islands-diving"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Similan Island
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Caribbean
                      </h3>

                      <ul className="pt-4 pb-4 mt-3 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/all-caribbean"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Caribbean
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/bahamas-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Bahamas
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/belize-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Belize
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/turks-and-caicos-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Turks & Caicos
                          </Link>
                        </li>
                      </ul>
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        The Americas
                      </h3>
                      <ul className="pt-4 pl-[20px]">
                        {/* <span className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                          Mexico
                        </span> */}
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/liveaboards-cocos-island"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Cocos Island
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/thailand-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Mexico
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/sea-of-cortez-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Sea of Cortez
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/socorro-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Socorro Islands
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/galapagos-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Galapagos
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Asia Pacific
                      </h3>

                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/fiji-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Fiji
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/fiji-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Fiji
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <span className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                          Micronesia
                        </span>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/palau-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Palau
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <span className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                          Australia
                        </span>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/great-barrier-reef-liveaboards"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Great Barrier Reef
                          </Link>
                        </li>
                      </ul>
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Indian Ocean, Africa & Middle East
                      </h3>
                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/maldives-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Maldives
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link href={"/liveaboard/red-sea-liveaboards"} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Red Sea
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Europe
                      </h3>

                      <ul className="pt-4 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/liveaboard/norway"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Norway
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="border-b border-solid border-[#B6D6E2] w-full">
              <div className="flex items-center gap-2" ref={resortOutside}>
                <Link
                  href="javascript:void(0)"
                  onClick={() => {
                    handleResorts();
                  }}
                  className={`w-full py-5 flex items-center group gap-2 992-1199:gap-1 ${router?.pathname === "/resort-destination/[...index]" ? "!text-[#F57415] btn-orange" : resorts ? "!text-[#F57415] btn-orange" : ""} `}
                >
                  Resorts
                  <Image
                    src={angleDown}
                    alt="Vercel Logo"
                    className={`transform ${resorts ? "rotate-180" : ""
                      } cursor-pointer`}
                    width={10}
                    height={6}
                    priority
                  />
                </Link>
              </div>
              <div
                id="dropdown-menu5"
                className={`opacity-1 ${resorts ? "pointer-events-auto" : "hidden pointer-events-none"
                  } w-full h-[40vh] overflow-y-auto mt-[20px] transition-all duration-600 ease-in-out origin-top-right bg-[#fff] focus:outline-none 768-991:pb-1 pb-10`}
              >
                <div className="flex flex-wrap 768-991:flex-wrap pt-5">
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Southeast Asia
                      </h3>

                      <ul className="pt-4 pl-[20px]">
                        <Link href={"/all-resorts"} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            All Resorts
                        </Link>
                        <Link href={"/resort-destination/indonesia-dive-resorts"} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Indonesia
                        </Link>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/indonesia-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Indonesia
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/bali-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Bali
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/raja-ampat-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Raja Ampot
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <Link href={"/resort-destination/philippines-dive-resorts"} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Philippines
                        </Link>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/philippines-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Philippines
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pb-4 pl-[20px]">
                        <Link href={"/resort-destination/malaysia"} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Malaysia
                        </Link>

                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/sipadan-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Sipidan
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Caribbean
                      </h3>

                      <ul className="pt-4 pb-4 mt-3 pl-[20px]">
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/caribbean-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Caribbean
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/belize-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Belize
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/bonaire-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Bonaire
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/cayman-islands-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Cayman Islands
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/roatan-utila-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Roatan, Utila, & Guanaja
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/bahamas-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Bahamas
                          </Link>
                        </li>
                      </ul>
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        The Americas
                      </h3>
                      <ul className="pt-4 pl-[20px]">
                        <span className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                          Mexico
                        </span>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/mexico-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            All Mexico
                          </Link>
                        </li>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/cozumel-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Cozumel
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="768-991:w-full w-3/12 pb-5">
                    <div className="pl-[10px] w-[90%]">
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Asia Pacific
                      </h3>

                      <ul className="pt-4 pl-[20px]">
                        <Link href={"/resort-destination/fiji-dive-resorts"} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Fiji
                        </Link>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                        <span className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                          Micronesia
                        </span>
                        <li onClick={handleCloseHamburger}>
                          <Link
                            href={"/resort-destination/palau-dive-resorts"}
                            className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                          >
                            Palau
                          </Link>
                        </li>
                      </ul>
                      <ul className="pt-4 pl-[20px]">
                          <Link
                            href={"/resort-destination/french-polynesia-dive-resorts"}
                            onClick={handleCloseHamburger}
                            className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer"
                          >
                            French Polynesia
                          </Link>
                      </ul>
                      <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                        Indian Ocean, Africa & Middle East
                      </h3>
                      <ul className="pt-4 pl-[20px]">
                        <Link href={'/resort-destination/maldives-dive-resorts'} onClick={handleCloseHamburger} className="font-poppins-semiBold pb-3 pt-[15px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                            Maldives
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <GuidedDropdown
              setAllFalse={setAllFalse}
              dropdownRef={dropdownRef4}
              openState={headerBG4}
              onClick={() => toggleHeaderBG(3)}
              handleCloseHamburger={handleCloseHamburger}
            />
            <IdeasDropdown
              setAllFalse={setAllFalse}
              dropdownRef={dropdownRef5}
              openState={headerBG5}
              onClick={() => toggleHeaderBG(4)}
              handleCloseHamburger={handleCloseHamburger}
            />
            <SpecialListDropdown
              setAllFalse={setAllFalse}
              dropdownRef={dropdownRef6}
              openState={headerBG6}
              onClick={() => toggleHeaderBG(5)}
              handleCloseHamburger={handleCloseHamburger}
            />
          </ul>
        </div>
        <SearchBar isShow={search} onClick={handleCloseSearch} />
      </header>
    </>
  );
}
