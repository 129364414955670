"use client";
import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import angleDown from "../assets/images/angleDown.svg";
import { useHeader } from "../hooks/useHeader";
import { useRouter } from "next/router";
import mega from "../assets/images/mega1.jpg";

const LiveaboardDropdown = ({
  onClick,
  openState,
  dropdownRef,
  setAllFalse,
  liveboardMenus,
  closeLiveaboard
}) => {
  const router = useRouter();
  // const [isOpen, setIsOpen] = useState(openState)
  // const dropdownRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false);
    });
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(()=>{
          openState && closeLiveaboard(1);
        },1000)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  // const toggleDropdown = () => {
  //   setIsOpen(openState)
  // }

  const handleLinkClick = () => {
    // toggleDropdown();
    onClick();
  };

  return (
    <div className="nav-link">
      <li ref={dropdownRef}
        className={`flex items-center gap-2 992-1199:gap-1 group btn-hover ${
          router?.pathname === "/liveaboard-destination/[...index]" ||
          router?.pathname === "/liveaboard/[...index]"
            ? "btn-hover-desti"
            : ""
        }`}
      >
        <Link
          href="javascript:void(0)"
          onClick={handleLinkClick}
          className={`flex items-center gap-2 992-1199:gap-1 group btn-hover ${
            router?.pathname === "/liveaboard-destination/[...index]" ||
            router?.pathname === "/liveaboard/[...index]"||
            router?.pathname === "/all-liveaboard"
              ? "text-[#F57415] btn-orange"
              : "text-black"
          } ${
            openState && "!text-[#F57415] btn-orange"
          } hover:text-[#F57415] group-hover:text-[#F57415]`}
          id="dropdown-button"
        >
          Liveaboard
          <Image
            src={angleDown}
            alt="Dropdown Arrow"
            width={10}
            height={6}
            className={`transform ${
              openState ? "rotate-180" : ""
            } cursor-pointer`}
          />
        </Link>
      </li>
      {openState && (
        <>
          <div className="mega-overlay" onClick={handleLinkClick}></div>

          <div
            className={`absolute ${
              scrolled ? "top-[75px] 1260-1365:top-[74px]" : "top-[95px]"
            } h-[calc(80vh-100px)] 992-1199:overflow-y-auto 992-1199:px-10 left-0 -z-[1] w-full transition-all duration-600 ease-in-out origin-top-right bg-[#F8FCFF] border-b-[20px] border-solid border-[#F8FCFF] shadow-lg focus:outline-none px-40 1366-1439:px-20 pb-10 overflow-y-auto scrollbar-hidden`}
          >
            <div className="flex flex-wrap 768-991:flex-wrap pt-5">
              <div className="768-991:w-full w-3/12 pb-5">
                <div className="pl-[10px] w-[90%]">
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    Southeast Asia
                  </h3>

                  <ul className="pt-2 pl-[20px]">
                    <Link href={"/all-liveaboard"} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      All Liveaboards
                    </span>
                    </Link>

                    <Link href={"/liveaboard-destination/indonesia-liveaboards"} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Indonesia
                    </span>
                    </Link>

                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/indonesia-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        All Indonesia
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/komodo-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Komodo
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/raja-ampat-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Raja Ampat
                      </Link>
                    </li>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <Link href={"/liveaboard-destination/philippines-liveaboards"} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Philippines
                    </span>
                    </Link>

                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/philippines-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        All Philippines
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/tubbataha-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Tubbataha
                      </Link>
                    </li>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <Link href={"/liveaboard-destination/thailand-liveaboards"} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Thailand
                    </span>
                    </Link>

                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/thailand-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        All Thailand
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/similan-islands-diving"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Similan Island
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="768-991:w-full w-3/12 pb-5">
                <div className="pl-[10px] w-[90%]">
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    Caribbean
                  </h3>

                  <ul className="pt-4 pb-4 mt-3 pl-[20px]">
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/all-caribbean"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        All Caribbean
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/bahamas-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Bahamas
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/belize-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Belize
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/turks-and-caicos-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Turks & Caicos
                      </Link>
                    </li>
                  </ul>
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    The Americas
                  </h3>
                  <ul className="pt-2 pl-[20px]">
                    {/*<span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">*/}
                    {/*  Mexico*/}
                    {/*</span>*/}
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/liveaboards-cocos-island"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Cocos Island
                    </span>
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"#"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Mexico
                    </span>
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/sea-of-cortez-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Sea of Cortez
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/socorro-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Socorro Islands
                      </Link>
                    </li>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/galapagos-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                       <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Galapagos
                    </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="768-991:w-full w-3/12 pb-5">
                <div className="pl-[10px] w-[90%]">
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    Asia Pacific
                  </h3>

                  <ul className="pt-2 pl-[20px]">
                    <Link href={"/liveaboard-destination/fiji-liveaboards"} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Fiji
                    </span>
                    </Link>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/fiji-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        All Fiji
                      </Link>
                    </li>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Micronesia
                    </span>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/palau-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Palau
                      </Link>
                    </li>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Australia
                    </span>
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/great-barrier-reef-liveaboards"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Great Barrier Reef
                      </Link>
                    </li>
                  </ul>
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    Indian Ocean, Africa & Middle East
                  </h3>
                  <ul className="pt-2 pl-[20px]">
                    <Link href={'/liveaboard-destination/maldives-liveaboards'} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Maldives
                    </span>
                    </Link>
                  </ul>
                  <ul className="pt-2 pl-[20px]">
                    <Link href={'/liveaboard-destination/red-sea-liveaboards'} onClick={handleLinkClick}>
                    <span className="font-poppins-semiBold pb-2 pt-[10px] block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] hover:text-[#F57415] cursor-pointer">
                      Red Sea
                    </span>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="768-991:w-full w-3/12 pb-5">
                <div className="pl-[10px] w-[90%]">
                  <h3 className="text-[#3DABC2] rounded-tl-[8px] rounded-bl-[8px] mt-3 w-full py-5 ps-5 pe-10 1440-1599:pe-14 bg-[#E4F4FB] text-[18px] 1440-1599:text-[16px] 992-1199:text-[16px]">
                    Europe
                  </h3>

                  <ul className="pt-2 pl-[20px]">
                    <li onClick={handleLinkClick}>
                      <Link
                        href={"/liveaboard-destination/norway"}
                        className="flex items-center gap-1 font-poppins-medium text-[13px]  text-[#000] leading-[24px] tracking-[-0.13px] py-[1px] hover:text-[#3DABC2]"
                      >
                        Norway
                      </Link>
                    </li>
                  </ul>
                  <div className="pl-[10px] w-[80%] h-full flex flex-col justify-between ml-auto mt-[80px]">
                    <div className="dropdown-image 1600-1679:!w-[233px]  !h-auto pt-4 mt-auto 768-991:w-[50%] relative">
                      <div
                        className={`flex flex-col justify-end items-start gap-3 px-6 pb-6 relative z-10 h-full`}
                      >
                        <span className="font-poppins-semiBold pb-2 block text-lg 1260-1365:text-[16px] 992-1199:text-[16px]  leading-[22px] tracking-[-0.18px] text-white">
                          Moorea 2023 Trip Report
                        </span>
                        <p className="font-poppins-regular  text-[13px] leading-[18px] tracking-[0.26px] text-white pb-2">
                          Observing and photographing humpback whales underwater
                          is one of our favorite ocean activities, and is
                          something that never gets old. Every year since
                          2014...
                        </p>
                        <Link
                          href="javascript:void(0)"
                          className="bg-[#3DABC2] py-2 px-5  text-sm text-white rounded-full border-[2px] border-solid border-[#3DABC2] inline-flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]"
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default LiveaboardDropdown;
