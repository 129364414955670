import {Base_url} from "../utils/constant";

const getDropDown = async () => {
    let res = await fetch(
        Base_url+`/api/header-menus`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
const mainSearch = async (filter) => {
    let res = await fetch(
        Base_url+`/api/main-search?${new URLSearchParams({
            filter
        })}`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
const headerSearch = async (filter) => {
    let res = await fetch(
        Base_url+`/api/header-search?${new URLSearchParams({
            filter
        })}`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
const getRedirect = async () => {
    let res = await fetch(
        Base_url+`/api/seo-redirects`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
export {getDropDown,mainSearch,getRedirect,headerSearch};