'use client'
import React, { useState, useRef, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import angleDown from '../assets/images/angleDown.svg'
import { useRouter } from 'next/router'

const GuidedDropdown = ({
  onClick,
  openState,
  dropdownRef,
  setAllFalse,
  handleCloseHamburger,
  guidedGroupTrips
}) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false)
    })
  })

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if(window.innerWidth >= 992){
          setTimeout(()=>{openState && setAllFalse();},500)
        }
        else{
          openState && setAllFalse();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });



  const handleLinkClick = () => {
    onClick()
  }

  return (
    <div ref={dropdownRef} className='nav-link relative 768-991:w-full'>
      <li
        className={`flex items-center gap-2 992-1199:gap-1 group btn-hover 768-991:border-b 768-991:border-solid 768-991:border-[#B6D6E2] 768-991:w-full ${
          router?.pathname === '/guided-group-trips' ||
          router?.pathname === '/guided-group-trips'
            ? 'btn-hover-desti'
            : ''
        }`}
      >
        <Link
          href='javascript:void(0)'
          onClick={handleLinkClick}
          className={`flex items-center gap-2 992-1199:gap-1 group btn-hover 768-991:w-full 768-991:py-5 ${
            router?.pathname === '/guided-group-trips' ||
            router?.pathname === '/guided-group-trips'
              ? 'text-[#F57415] btn-orange'
              : 'text-black'
          } ${
            openState && '!text-[#F57415] btn-orange'
          } hover:text-[#F57415] group-hover:text-[#F57415]`}
          id="dropdown-button1"
        >
          Guided Group Trips
          <Image
            src={angleDown}
            alt='Dropdown Arrow'
            width={10}
            height={6}
            className={`transform ${
              openState ? 'rotate-180' : ''
            } cursor-pointer`}
          />
        </Link>
      </li>
      {openState && (
        <>
          <div
            className={`absolute ${
              scrolled ? 'top-[45px] 1260-1365:top-[74px]' : 'top-[45px]'
            } h-auto 768-991:static 768-991:px-0 768-991:min-w-[100%] min-w-[300px] left-0 z-[1] px-4 py-4 w-auto transition-all duration-600 ease-in-out bg-[#FFF] rounded-bl-[10px] rounded-br-[10px]`}
          >
            <ul>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link

                  href="/guided-group-trips"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  All Trips
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link
                  href="/guided-group-trips?type=African Safari"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  African Safaris
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link
                  href="/guided-group-trips?type=Whale Encounters"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  Swim with Whales
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link
                  href="/guided-group-trips?type=Snorkel Trips"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  Snorkel Trips
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link
                  href="/guided-group-trips?type=2024"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  2024 Trips
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className='w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out'>
                <Link
                  href="/guided-group-trips?type=2025"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  2025 Trips
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className="w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out">
                <Link
                  href="/guided-group-trips?type=2026"
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  2026 Trips
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}
export default GuidedDropdown
