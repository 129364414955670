'use client'
import React, { useState, useRef, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import angleDown from '../assets/images/angleDown.svg'
import { useRouter } from 'next/router'

const SpecialListDropdown = ({
  onClick,
  openState,
  dropdownRef,
  setAllFalse,
  handleCloseHamburger
  // liveboardMenus
}) => {
  const router = useRouter()
  // const [isOpen, setIsOpen] = useState(openState)
  // const dropdownRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false)
    })
  })

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if(window.innerWidth >= 992){
          setTimeout(()=>{openState && setAllFalse();},500)
        }
        else{
          openState && setAllFalse();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleLinkClick = () => {
    // toggleDropdown();
    onClick()
  }

  return (
    <div ref={dropdownRef} className="nav-link relative 768-991:w-full">
      <li
        className={`flex items-center gap-2 992-1199:gap-1 group btn-hover 768-991:border-b 768-991:border-solid 768-991:border-[#B6D6E2] 768-991:w-full ${
          router?.pathname === "/specials-list" ||
          router?.pathname === "/specials-list"
            ? "btn-hover-desti"
            : ""
        }`}
      >
        <Link
          href="javascript:void(0)"
          onClick={handleLinkClick}
          className={`flex items-center gap-2 992-1199:gap-1 group btn-hover 768-991:w-full 768-991:py-5 ${
            router?.pathname === "/specials-list" ||
            router?.pathname === "/specials-list"
              ? "text-[#F57415] btn-orange"
              : "text-black"
          } ${
            openState && "!text-[#F57415] btn-orange"
          } hover:text-[#F57415] group-hover:text-[#F57415]`}
          id="dropdown-button"
        >
          Special Offers
          <Image
            src={angleDown}
            alt="Dropdown Arrow"
            width={10}
            height={6}
            className={`transform ${
              openState ? "rotate-180" : ""
            } cursor-pointer`}
          />
        </Link>
      </li>
      {openState && (
        <>
          <div 
            className={`absolute ${
              scrolled ? "top-[45px] 1260-1365:top-[74px]" : "top-[45px]"
            } h-auto 768-991:static 768-991:px-0 768-991:min-w-[100%] min-w-[300px] left-0 z-[1] px-4 py-4 w-auto transition-all duration-600 ease-in-out bg-[#FFF] rounded-bl-[10px] rounded-br-[10px]`}
          >
            <ul>
              <li onClick={handleCloseHamburger} className="w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out">
                <Link
                  href={"/specials-list"}
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  All Specials
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className="w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out">
                <Link
                  href={"/specials-list?type=Liveaboard"}
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  Liveaboard Deals
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className="w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out">
                <Link
                  href={"/specials-list?type=Resort"}
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  Resort Deals
                </Link>
              </li>
              <li onClick={handleCloseHamburger} className="w-[100%] bg-[#FFF] group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out">
                <Link
                  href={"/specials-list?type=Last Minute"}
                  className="font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-[#FFF] block px-[10px] py-[10px] transition-all duration-600 ease-in-out"
                >
                  Last Minute Specials
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
export default SpecialListDropdown
